import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { eventBus } from '@/services/event-bus.service';
import { TripState, QuoteStatus, Languages, NewUiPreference } from '@/types/consts';
import { config } from '@/config/config';
import { userService } from '@/services/user.service';
import appAvatar from '@/components/app-avatar.vue';
export default defineComponent({
    components: {
        'app-avatar': appAvatar,
    },
    props: {
        numberOfUnreadConversation: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            nationalities: [],
            isChangeToOldUiLoading: false,
            Languages: Languages,
            scrollLastPosition: 0,
            isHeaderVisible: true,
        };
    },
    created() {
        // listen to scroll, to see if the header should be visible or not
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        logout() {
            this.$router.push({ name: RoutesNames.signout });
        },
        handleScroll() {
            const currPosition = window.scrollY;
            const lastPosition = this.scrollLastPosition;
            // if scroll up - show header
            this.isHeaderVisible = currPosition < lastPosition;
            const isTopAndVisible = currPosition < 40; // avg header height
            // let the trip bar know if the header is shown - to set its top
            eventBus.$emit('setTripBarTop', !this.isHeaderVisible, isTopAndVisible);
            this.scrollLastPosition = currPosition;
        },
        isNLGov() {
            if (this.loggedinUser) {
                let agencyId = this.loggedinUser?.agencyId;
                if (config.env.isProd) {
                    return agencyId === 288033 || agencyId === 276121;
                }
                else {
                    return agencyId === 276121;
                }
            }
            return false;
        },
        async switchToOldGTP() {
            if (this.isMobile) {
                return; //prevent on mobile
            }
            this.isChangeToOldUiLoading = true; // change system language to English before redirecting user to old UI in case it's not German
            const language = this.systemLanguage === 'de-DE' ? 'de-DE' : 'en-GB';
            await this.$store.dispatch({ type: 'userStore/setSystemLanguage', language });
            userService.updateUserNewUiParameter(false).then((response) => {
                let newUrl = config.wsUrl + '/';
                let last = '';
                if (document.location.href) {
                    if (document.location.href.endsWith('trips')) {
                        newUrl += 'TripsMonitor?activate=true';
                        window.location.replace(newUrl);
                    }
                    else if (document.location.href.indexOf('results') > 0) {
                        newUrl += 'Trip/' + this.trip.id + '#/';
                        window.location.replace(newUrl);
                    }
                    else if (document.location.href.indexOf('cart') > 0) {
                        newUrl += 'Trip/' + this.trip.id + '#/';
                        window.location.replace(newUrl);
                    }
                    else if (this.trip) {
                        newUrl += 'Trip/' + this.trip.id + '#/';
                        let splitted = document.location.href.split('/');
                        if (splitted && splitted.length > 0) {
                            last = splitted[splitted.length - 1];
                            if (last === 'flight') {
                                newUrl += 'flights/search';
                            }
                            else if (last === 'hotel') {
                                newUrl += 'hotels/search';
                            }
                            else if (last === 'car') {
                                newUrl += 'cars/search';
                            }
                            else if (last === 'heathrowexpres') {
                                newUrl += 'heathrowexpress/search';
                            }
                            else if (last === 'groundService') {
                                newUrl += 'groundservice/search';
                            }
                            else {
                                newUrl += last.toLowerCase() + '/search';
                            }
                        }
                        window.location.assign(newUrl);
                    }
                    else {
                        window.location.assign(newUrl);
                    }
                }
            });
        },
        emitOpenMenu() {
            eventBus.$emit('setMenuDrawer', true);
        },
        toggleContactSupportVisible() {
            eventBus.$emit('setSupportPopoverVisibility', true);
        },
        toggleConversationsVisible() {
            this.$emit('toggleConversationsVisible');
        },
        async changeDisplayLang(lang) {
            if (lang.disabled) {
                return;
            }
            this.$i18n.locale = lang.code;
            eventBus.$emit('setLanguage', lang.code);
            const language = lang.isoCode;
            if (this.systemLanguage !== language) {
                await this.$store.dispatch({ type: 'userStore/setSystemLanguage', language });
                userService.clearUseInfoCaching();
            }
            this.$router.go(0);
        },
        goToCart() {
            this.$router.push({ name: RoutesNames.tripCart, params: { tripId: this.trip.id } });
        },
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'] || null;
        },
        showSwitchUiButton() {
            //ONLY GTP ACCESS
            if (!this.isChangeToOldUiLoading && this.loggedinUser?.newUiPreference === NewUiPreference.GtpOnly) {
                this.switchToOldGTP();
                return false;
            }
            else {
                return this.loggedinUser?.newUiPreference === NewUiPreference.Both && !this.$store.getters.isMobile;
            }
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        systemLanguage() {
            return this.$store.getters['userStore/systemLanguage'];
        },
        isTripCancelled() {
            return this.trip && this.trip.state === TripState.Cancelled;
        },
        showTripInfo() {
            // TODO: In case under tripApp show trip info
            return ((this.$route.name === RoutesNames.tripCart ||
                this.$route.name === RoutesNames.hotelResults ||
                this.$route.name === RoutesNames.euRailResults) &&
                this.isMobile &&
                this.trip?.id);
        },
        hotelSearchOptions() {
            return this.$store.getters['hotelStore/searchOptions'];
        },
        locFirstWord() {
            // TODO: Make dynamic according to product type
            if (!this.trip) {
                return '';
            }
            return (this.trip.DestinationCityName ||
                this.trip.destinationName ||
                this.trip.originName ||
                this.hotelSearchOptions?.destination?.Name.split(',')[0] ||
                null);
        },
        quotesLengthToShow() {
            // Show only hotel quotes && only those that are pending booking (= status Select = Enum 0)
            // TODO : after MVP show all
            const length = this.trip?.quotes?.reduce((acc, quote) => {
                if (quote.status === QuoteStatus.Selected) {
                    acc++;
                }
                return acc;
            }, 0);
            return length || '';
        },
        showContactSupport() {
            return !!this.contactSupportInfo && !this.isMobile;
        },
        isMobileHomePage() {
            return this.$route.name === RoutesNames.home && this.isMobile;
        },
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
});
