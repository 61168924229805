import { defineComponent } from 'vue';
import { requiredIf, numeric, alphaNum, helpers } from '@vuelidate/validators';
import { add, sub } from 'date-fns';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { alertService } from '@/services/alert.service';
import { useVuelidate } from '@vuelidate/core';
import { ValidateEach } from '@vuelidate/components';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'PassportVisaForm',
    components: {
        appSelect,
        appDatepicker,
        formValidationMsg,
        ValidateEach
    },
    props: {
        currData: {
            type: Object,
        },
        nationalities: {
            type: Array,
        },
        corporateUserId: {
            type: Number,
        },
        tripId: {
            type: Number,
            default: 0,
        },
        mandateFieldOptions: {
            type: Array,
            default: () => [],
        },
        mandateFields: {
            type: Array,
            default: () => [],
        },
        isUserHasEncryptedFields: {
            type: Boolean,
            default: false,
        },
        canEditProfile: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            formData: {
                userPassports: [],
                applicableCountry: '',
                redressNumber: '',
                tSA: '', // Known traveler property,
            },
            markFields: false,
            isLoadingBtn: false,
            isRemoveDialogOpen: false,
            itemToRemove: {},
        };
    },
    created() {
        this.formData = { ...this.formData, ...utilService.deepClone(this.currData) };
        if (!this.formData.userPassports.length && this.isMandateForm) {
            this.addEmptyPassportForm();
        }
    },
    methods: {
        async handleSubmit() {
            this.markFields = true;
            if (this.v$.$invalid) {
                return;
            }
            this.isLoadingBtn = true;
            try {
                await this.$store.dispatch({
                    type: 'userStore/savePassportInfo',
                    userPassportInfo: this.formData,
                    tripId: this.tripId,
                });
                this.$emit('checkMandateFieldsStatus');
                alertService.success('alert.general.saved');
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
            finally {
                this.isLoadingBtn = false;
            }
        },
        addEmptyPassportForm() {
            const passport = {
                id: 0,
                passportNumber: '',
                issueAt: '',
                nationality: '',
                validUntil: '',
                dateOfIssue: '',
                corporateUserPassportVisas: [],
                isPrimary: false,
                corporateUserId: this.corporateUserId,
            };
            this.formData.userPassports.push(passport);
            if (this.isMobile) {
                this.$nextTick(() => {
                    this.$refs.passports[this.formData.userPassports.length - 1].scrollIntoView({
                        block: 'nearest',
                        behavior: 'smooth',
                    });
                });
            }
        },
        removePassportForm(idx) {
            this.formData.userPassports.splice(idx, 1);
        },
        addEmptyVisaForm(passportIdx) {
            const visa = {
                visaNumber: '',
                validUntil: '',
                visaFor: '',
            };
            this.formData.userPassports[passportIdx].corporateUserPassportVisas.push(visa);
        },
        removeVisaForm(idx) {
            this.formData.visas.splice(idx, 1);
        },
        openRemoveDialog(itemId, passportIdx, visaIdx = null) {
            this.itemToRemove = { itemId, passportIdx, visaIdx };
            this.isRemoveDialogOpen = true;
        },
        async onRemoveItemConfirm() {
            const { itemId, passportIdx, visaIdx } = this.itemToRemove;
            if (visaIdx !== undefined && visaIdx !== null) {
                this.formData.userPassports[passportIdx].corporateUserPassportVisas.splice(visaIdx, 1);
            }
            else if (itemId === 0) {
                // new item
                this.removePassportForm(passportIdx);
            }
            else {
                try {
                    await this.$store.dispatch({ type: 'userStore/removeUserPassport', itemId, tripId: this.tripId });
                    this.formData.userPassports.splice(passportIdx, 1); // For UI to be updated fast
                }
                catch (err) {
                    loggerService.error(err);
                    alertService.error(this.$t('alert.general.error'), err);
                }
            }
            this.isRemoveDialogOpen = false;
        },
        unCheckOtherCards(currPassportIdx) {
            this.formData.userPassports.forEach((passport, idx) => {
                if (idx !== currPassportIdx) {
                    this.formData.userPassports[idx].isPrimary = false;
                }
            });
        },
        fillNationalityField(passportIdx) {
            if (!this.formData.userPassports[passportIdx].nationality) {
                this.formData.userPassports[passportIdx].nationality = this.formData.userPassports[passportIdx].issueAt;
            }
        },
        shortPassportNumber(passportIdx) {
            return this.formData?.userPassports[passportIdx]?.passportNumber?.substr(-3);
        },
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        canSeePassportData() {
            return (!this.isUserHasEncryptedFields || this.canEditProfile) && (!this.loggedinUser.isCorporateUser || this.corporateUserId === this.loggedinUser.id);
        },
        nationalityTypeOpts() {
            if (!this.nationalities) {
                return [];
            }
            return this.nationalities.map((n) => {
                return {
                    value: n.code,
                    label: `${n.name} (${n.code})`,
                };
            });
        },
        minDate() {
            const now = new Date();
            const yearsToSub = 120;
            const daysToSub = 1;
            const minDate = sub(now, { years: yearsToSub, days: daysToSub }).toISOString();
            return minDate;
        },
        maxDateExpCard() {
            const now = new Date();
            const yearsToSub = 10;
            const daysToSub = 1;
            const maxDate = add(now, { years: yearsToSub, days: daysToSub }).toISOString();
            return maxDate;
        },
        isMandateForm() {
            return this.mandateFields.includes('Passport Details');
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        validationRulesEach() {
            return {
                passportNumber: {
                    alphaNum,
                    required: requiredIf((value) => this.isMandateForm &&
                        (this.formData.userPassports.findIndex((passport) => passport.id === value.id) === 0)),
                },
                dateOfIssue: {
                    required: requiredIf((value) => this.isMandateForm &&
                        this.formData.userPassports.findIndex((passport) => passport.id === value.id) === 0),
                },
                issueAt: {
                    required: requiredIf((value) => this.isMandateForm &&
                        this.formData.userPassports.findIndex((passport) => passport.id === value.id) === 0),
                },
                nationality: {
                    required: requiredIf((value) => this.isMandateForm &&
                        this.formData.userPassports.findIndex((passport) => passport.id === value.id) === 0),
                },
                validUntil: {
                    required: requiredIf((value) => this.isMandateForm &&
                        this.formData.userPassports.findIndex((passport) => passport.id === value.id) === 0),
                },
                corporateUserPassportVisas: {
                    $each: helpers.forEach({
                        visaNumber: {
                            alphaNum,
                        },
                    }),
                },
            };
        },
    },
    validations() {
        return {
            formData: {
                redressNumber: {
                    numeric,
                },
                // Known traveler property
                tSA: {
                    alphaNum,
                },
                applicableCountry: {
                    required: requiredIf(() => {
                        return this.formData.redressNumber || this.formData.tSA;
                    }),
                },
            },
        };
    },
    watch: {
        currData(newData) {
            this.formData = { ...this.formData, ...utilService.deepClone(newData) };
        },
    },
});
