import { loggerService } from '@/services/logger.service';
import { userService } from '@/services/user.service';
import { utilService } from '@/services/util.service';
import store from './store';
export const userStore = {
    namespaced: true,
    state: {
        userProfile: null,
        systemLanguage: 'en-GB',
        tempPassengerInfo: null,
    },
    getters: {
        userProfile(state) {
            return state.userProfile;
        },
        systemLanguage(state) {
            return state.systemLanguage;
        },
        userUnitsSystem(state) {
            const user = store.getters['authStore/loggedinUser'];
            //check if any system unit was saved on local storage and if so use that as default
            const unitsSystemFromLocalStorage = userService.getUserUnitSystem();
            //check for null and not falsy because unit can be 0
            const unitsSystem = unitsSystemFromLocalStorage !== null ? unitsSystemFromLocalStorage : user.preferences.unitsSystem;
            return unitsSystem;
        },
        tempPassengerInfo(state) {
            return state.tempPassengerInfo;
        },
    },
    mutations: {
        // User Profile mutations
        setUserProfile(state, { userProfileData }) {
            state.userProfile = userProfileData;
        },
        setPersonalDetails(state, { updatedPersonalDetails }) {
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                ...utilService.deepClone(updatedPersonalDetails),
            };
        },
        setContactInfo(state, { updatedContactInfo }) {
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                userContactInfo: { ...utilService.deepClone(updatedContactInfo) },
            };
        },
        setPassportInfo(state, { updatedPassportInfo }) {
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                userPassportInfo: { ...utilService.deepClone(updatedPassportInfo) },
            };
        },
        setFrequentProgramInfo(state, { updatedFrequentProgramInfo }) {
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                frequentProgramInfo: { ...utilService.deepClone(updatedFrequentProgramInfo) },
            };
        },
        setTravelPreferences(state, { updatedTravelPreferences }) {
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                travelPreferences: { ...utilService.deepClone(updatedTravelPreferences) },
            };
        },
        addProfileFormOfPayment(state, { addedProfileFormOfPayment }) {
            const formsOfPayment = utilService.deepClone(state.userProfile.formOfPayment);
            if (addedProfileFormOfPayment.creditCard.isDefault) {
                formsOfPayment.forEach((f) => (f.creditCard.isDefault = false));
            }
            formsOfPayment.push(addedProfileFormOfPayment);
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                formOfPayment: formsOfPayment,
            };
        },
        updateProfileFormOfPayment(state, { updatedProfileFormOfPayment }) {
            const formsOfPayment = utilService.deepClone(state.userProfile.formOfPayment);
            const updatedFopIdx = formsOfPayment.findIndex((fop) => fop.id === updatedProfileFormOfPayment.id);
            formsOfPayment.splice(updatedFopIdx, 1, updatedProfileFormOfPayment);
            formsOfPayment.forEach((fop, idx) => {
                if (idx !== updatedFopIdx) {
                    fop.creditCard.isDefault = false;
                }
            });
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                formOfPayment: formsOfPayment,
            };
        },
        removeProfileFormOfPayment(state, { itemId }) {
            state.userProfile.formOfPayment = state.userProfile.formOfPayment.filter((fop) => fop.id !== itemId);
        },
        removeUserPassport(state, { itemId }) {
            state.userProfile.userPassportInfo.userPassports = state.userProfile.userPassportInfo.userPassports.filter((passport) => passport.id !== itemId);
        },
        removeProfileFrequentProgram(state, { itemId }) {
            const frequentPrograms = state.userProfile.frequentProgramInfo.frequentPrograms.filter((program) => program.id !== itemId);
            state.userProfile = {
                ...utilService.deepClone(state.userProfile),
                frequentProgramInfo: { ...utilService.deepClone(state.userProfile.frequentProgramInfo), frequentPrograms },
            };
            // state.userProfile.frequentProgramInfo.frequentPrograms =
            //   state.userProfile.frequentProgramInfo.frequentPrograms.filter((program) => program.id !== itemId);
        },
        removeProfileDBRailProgram(state, { itemId }) {
            state.userProfile.frequentProgramInfo.dBRailPrograms =
                state.userProfile.frequentProgramInfo.dBRailPrograms.filter((program) => program.id !== itemId);
        },
        setSystemLanguage(state, { language }) {
            state.systemLanguage = language;
        },
        setTempPassengerInfo(state, { passengerInfo }) {
            state.tempPassengerInfo = passengerInfo;
        },
    },
    actions: {
        // User Profile actions
        async loadUserProfile({ commit }, { userId, tripId, passengerInfo }) {
            if (passengerInfo) {
                commit({ type: 'setTempPassengerInfo', passengerInfo });
            }
            const userProfileData = await userService.getUserProfile(userId, tripId);
            commit({ type: 'setUserProfile', userProfileData });
        },
        // Save / Update
        async savePersonalDetails({ commit }, { userPersonalDetails, tripId }) {
            const updatedPersonalDetails = await userService.saveUserPersonalDetails(userPersonalDetails, tripId);
            commit({ type: 'setPersonalDetails', updatedPersonalDetails });
        },
        async saveContactInfo({ commit, state }, { userContactInfo, tripId }) {
            const updatedContactInfo = await userService.saveUserContactInfo(userContactInfo, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'setContactInfo', updatedContactInfo });
        },
        async savePassportInfo({ commit, state }, { userPassportInfo, tripId }) {
            const updatedPassportInfo = await userService.saveUserPassportInfo(userPassportInfo, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'setPassportInfo', updatedPassportInfo });
        },
        async saveFrequentProgramsInfo({ commit, state }, { frequentProgramsInfo, tripId }) {
            const updatedFrequentProgramInfo = await userService.saveFrequentProgramInfo(frequentProgramsInfo, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'setFrequentProgramInfo', updatedFrequentProgramInfo });
        },
        async saveTravelPreferences({ commit, state }, { travelPreferences, tripId }) {
            const updatedTravelPreferences = await userService.saveTravelPreferences(travelPreferences, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'setTravelPreferences', updatedTravelPreferences });
        },
        // Add
        async addProfileFormOfPayment({ commit, state }, { profileFormOfPayment, tripId }) {
            const addedProfileFormOfPayment = await userService.addProfileFormOfPayment(profileFormOfPayment, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'addProfileFormOfPayment', addedProfileFormOfPayment });
        },
        // Update
        async updateProfileFormOfPayment({ commit, state }, { defaultCardFop, tripId }) {
            const updatedProfileFormOfPayment = await userService.updateProfileFormOfPayment(defaultCardFop, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'updateProfileFormOfPayment', updatedProfileFormOfPayment });
        },
        // Remove
        async removeProfileFormOfPayment({ commit, state }, { itemId, tripId }) {
            await userService.removeProfileFormOfPayment(itemId, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'removeProfileFormOfPayment', itemId });
        },
        async removeUserPassport({ commit, state }, { itemId, tripId }) {
            await userService.removeUserPassport(itemId, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'removeUserPassport', itemId });
        },
        async removeProfileFrequentProgram({ commit, state }, { itemId, tripId }) {
            await userService.removeProfileFrequentProgram(itemId, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'removeProfileFrequentProgram', itemId });
        },
        async removeProfileDBRailProgram({ commit, state }, { itemId, tripId }) {
            await userService.removeProfileDBRailProgram(itemId, state.userProfile.userPersonalDetails.id, tripId);
            commit({ type: 'removeProfileDBRailProgram', itemId });
        },
        async setSystemLanguage({ commit }, { language }) {
            try {
                commit({ type: 'setSystemLanguage', language });
                await userService.setSystemLanguage(language);
            }
            catch (err) {
                loggerService.error(err);
            }
        },
    },
};
