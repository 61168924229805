import { defineComponent } from 'vue';
import { Products, RailTypes } from '@/types/consts';
import { RoutesNames } from '@/router';
import avatarList from '@/components/avatar-list.vue';
import ticketLayout from '@/components/ticket-layout.vue';
export default defineComponent({
    components: { avatarList, ticketLayout },
    props: {
        trip: {
            type: Object,
        },
        loggedinUser: {
            type: Object,
        },
    },
    methods: {
        isQuoteInTrip(productName) {
            if (RailTypes.includes(productName) && RailTypes.some((railType) => this.trip.Products.includes(railType))) {
                return true;
            }
            return this.trip.Products?.includes(productName);
        },
        openTripTarget() {
            const user = this.loggedinUser;
            if ((!user.corporationId || this.isBooker) && !this.isMobile) {
                return '_blank';
            }
            else {
                return '_self';
            }
        },
        passengerName(passenger) {
            // full name is max 20 letters - 19 is first name + last name and 1 space
            if (passenger.firstName.length + passenger.lastName.length > 19) {
                return `${passenger.firstName} ${passenger.lastName[0]}`;
            }
            else {
                return `${passenger.firstName} ${passenger.lastName}`;
            }
        },
    },
    computed: {
        isBooker() {
            return this.$store.getters['authStore/isBooker'];
        },
        statusClass() {
            //TODO: use utilService
            if (!this.trip.StateName) {
                return '';
            }
            const name = this.trip.StateName;
            return name.toLowerCase().split(' ').join('-');
        },
        hasHotelQuote() {
            return this.trip.Products?.includes(Products.Hotel);
        },
        getUniqeProductIconMap() {
            // Need to take it to const file
            const productIconMap = {
                flight: { key: 'flight', order: 0 },
                hotel: { key: 'domain', order: 1 },
                car: { key: 'directions_car', order: 2 },
                groundService: { key: 'local_taxi', order: 3 },
                rail: { key: 'directions_railway', order: 4 },
                parking: { key: 'local_parking', order: 5 },
                staySafe: { key: 'privacy_tip', order: 6 },
                compensation: { key: 'eco', order: 7 },
            };
            const currProductIconMap = this.loggedinUser.products.reduce((acc, product) => {
                if (RailTypes.includes(product)) {
                    product = 'rail';
                }
                if (acc[product]) {
                    return acc;
                }
                if (!productIconMap.hasOwnProperty(product)) {
                    productIconMap[product] = { key: 'help', order: Object.keys(productIconMap).length };
                }
                acc[product] = productIconMap[product];
                return acc;
            }, {});
            //order by product
            const ordered = Object.keys(currProductIconMap)
                .sort((a, b) => {
                return currProductIconMap[a].order - currProductIconMap[b].order;
            })
                .reduce((accumulator, key) => {
                accumulator[key] = currProductIconMap[key].key;
                return accumulator;
            }, {});
            return ordered;
        },
        routesNames() {
            return RoutesNames;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        passengers() {
            return this.trip.Passengers?.map((passenger) => {
                const names = passenger.Name.split(' ');
                return {
                    firstName: names[0],
                    lastName: names[names.length - 1],
                };
            });
        },
        destinationName() {
            const { TripDestination, DestinationName, DestinationCityName, Destination } = this.trip;
            return DestinationCityName ? DestinationCityName : DestinationName ? DestinationName : Destination ? Destination : TripDestination;
        },
    },
});
