import { defineComponent } from 'vue';
import { alertService } from '@/services/alert.service';
import { loggerService } from '@/services/logger.service';
import { requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { MealType, FlightClass, HotelRoomType, HotelBedType, CarTransmissionType, } from '@/types/types';
import appAutocomplete from '@/components/form/app-autocomplete.vue';
import appSelect from '@/components/app-select.vue';
import airportAutocomplete from '@/components/airport-autocomplete.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
//TODO: service to lazy-loading
import { carService } from '@/modules/products/car/services/car.service';
export default defineComponent({
    name: 'TravelPreferencesForm',
    components: {
        appSelect,
        appAutocomplete,
        airportAutocomplete,
        formValidationMsg,
    },
    props: {
        currData: {
            type: Object,
        },
        airplaneCarriers: {
            type: Array,
        },
        flightMealTypes: {
            type: Object,
        },
        hotelLoyaltyCodes: {
            type: Array,
        },
        corporateUserId: {
            type: Number,
        },
        tripId: {
            type: Number,
            default: 0,
        },
        mandateFieldOptions: {
            type: Array,
            default: () => [],
        },
        mandateFields: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            formData: {
                hotelPreferences: {
                    preferredHotelVendor: '',
                    nonPreferredHotelVendor: '',
                    hotelBedType: '',
                    hotelRoomType: '',
                    additionalHotelInformation: '',
                },
                carPreferences: {
                    preferredCarVendor: '',
                    nonPreferredCarVendor: '',
                    carType: '',
                    transmission: '',
                },
                flightPreferences: {
                    cabinClass: '',
                    mealType: '',
                    preferredAirlineCarrier: '',
                    nonPreferredAirlineCarrier: '',
                    homeAirport: '',
                    additionalFlightInformation: '',
                    additionalSeatInformation: '',
                    seat: '',
                },
            },
            carTypes: [],
            isLoadingBtn: false,
        };
    },
    async created() {
        this.setFormData();
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.carTypes = await carService.getCarTypes();
        },
        homeAirportChanged(val) {
            if (!val) {
                this.formData.flightPreferences.homeAirport = '';
            }
        },
        async handleSubmit() {
            this.isLoadingBtn = true;
            if (typeof this.formData.flightPreferences.homeAirport === 'object') {
                this.formData.flightPreferences.homeAirport = this.formData.flightPreferences.homeAirport.Name;
            }
            const travelPreferences = {
                ...this.formData,
                flightSearch: this.formData.flightPreferences,
                corporateUserId: this.corporateUserId,
            };
            try {
                await this.$store.dispatch({ type: 'userStore/saveTravelPreferences', travelPreferences, tripId: this.tripId });
                this.$emit('checkMandateFieldsStatus');
                alertService.success(this.$t('alert.general.saved'));
            }
            catch (err) {
                loggerService.error(err);
                alertService.error(this.$t('alert.general.error'), err);
            }
            finally {
                this.isLoadingBtn = false;
            }
        },
        setFormData() {
            const modifiedFormData = {
                ...this.formData,
                ...this.currData,
                flightPreferences: { ...this.formData.flightPreferences, ...this.currData.flightSearch },
                hotelPreferences: { ...this.formData.hotelPreferences, ...this.currData.hotelPreferences },
                carPreferences: { ...this.formData.carPreferences, ...this.currData.carPreferences },
            };
            this.formData = { ...modifiedFormData };
        },
        checkIsMandateField(fieldName) {
            const isMandateField = this.mandateFields.includes(fieldName);
            this.markFields = isMandateField;
            return isMandateField;
        },
    },
    computed: {
        carrierOpts() {
            if (!this.airplaneCarriers) {
                return [];
            }
            return this.airplaneCarriers.map((carrier) => {
                return {
                    value: carrier.code,
                    label: carrier.name,
                };
            });
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        flightMealTypesOpts() {
            const flightMealTypesValues = Object.values(MealType);
            const flightMealTypesKeys = Object.keys(MealType);
            return flightMealTypesValues.map((type, idx) => {
                return {
                    value: flightMealTypesKeys[idx],
                    label: type,
                };
            });
        },
        flightClassOpts() {
            const flightClassValues = Object.values(FlightClass);
            const flightClassKeys = Object.keys(FlightClass);
            return flightClassValues.map((type, idx) => {
                return {
                    value: flightClassKeys[idx],
                    label: type,
                };
            });
        },
        hotelRoomTypeOpts() {
            const hotelRoomTypeValues = Object.values(HotelRoomType);
            return hotelRoomTypeValues.map((type) => {
                return {
                    value: type,
                    label: type,
                };
            });
        },
        hotelBedTypeOpts() {
            const hotelBedTypeValues = Object.values(HotelBedType);
            return hotelBedTypeValues.map((type) => {
                return {
                    value: type,
                    label: type,
                };
            });
        },
        carTransmissionTypeOpts() {
            const carTransmissionTypeValues = Object.values(CarTransmissionType);
            return carTransmissionTypeValues.map((type) => {
                return {
                    value: type,
                    label: type,
                };
            });
        },
        carTypeOpts() {
            return this.carTypes?.map((type) => {
                return {
                    value: type,
                    label: type,
                };
            });
        },
        hotelLoyaltyCodesOpts() {
            return this.hotelLoyaltyCodes?.map((code) => {
                return {
                    value: code.ChainCode,
                    label: code.Name ? `${code.Name} (${code.ChainCode})` : code.ChainCode,
                };
            });
        },
        carAgencyVendorsOpts() {
            return this.$store.getters['carStore/rentalAgencyOptions'];
        },
    },
    validations() {
        return {
            formData: {
                hotelPreferences: {
                    preferredHotelVendor: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Preferred Hotel Vendor');
                        }),
                    },
                    nonPreferredHotelVendor: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Non Preferred Hotel Vendor');
                        }),
                    },
                    hotelBedType: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Hotel Bed Type');
                        }),
                    },
                    hotelRoomType: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Hotel Room Type');
                        }),
                    },
                    additionalHotelInformation: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Additional Hotel Information');
                        }),
                    },
                },
                carPreferences: {
                    preferredCarVendor: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Preferred Car Vendor');
                        }),
                    },
                    nonPreferredCarVendor: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Non Preferred Car Vendor');
                        }),
                    },
                    carType: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Car Type');
                        }),
                    },
                    transmission: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Car Transmission');
                        }),
                    },
                },
                flightPreferences: {
                    cabinClass: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Class');
                        }),
                    },
                    mealType: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Meal Type');
                        }),
                    },
                    preferredAirlineCarrier: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Preferred Airline Carrier');
                        }),
                    },
                    nonPreferredAirlineCarrier: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Non Preferred Airline Carrier');
                        }),
                    },
                    homeAirport: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Home Airport');
                        }),
                    },
                    additionalFlightInformation: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Additional Flight Information');
                        }),
                    },
                    additionalSeatInformation: {
                        required: requiredIf(() => {
                            return this.checkIsMandateField('Additional Seat Information');
                        }),
                    },
                },
            },
        };
    },
    // watch: {
    //   currData(newCurrData) {
    //     if (newCurrData) {
    //       const modifiedFormData = {
    //         ...this.formData,
    //         flightPreferences: { ...this.formData.flightPreferences, ...newCurrData.flightSearch },
    //         hotelPreferences: { ...this.formData.hotelPreferences, ...newCurrData.hotelPreferences },
    //         carPreferences: { ...this.formData.carPreferences, ...newCurrData.carPreferences },
    //       };
    //       this.formData = { ...modifiedFormData };
    //     }
    //   },
    // },
});
