import { defineComponent } from 'vue';
export default defineComponent({
    data() {
        return {
            lorem: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero fuga amet debitis id assumenda necessitatibus
          consequatur at, qui praesentium voluptatem itaque dolore harum ullam, dicta sequi incidunt ratione magnam
          accusamus voluptas nemo aut? Ullam eum ab, rem nesciunt ut distinctio iste, ea beatae est mollitia doloribus,
          cumque ipsam debitis consectetur reiciendis architecto ipsum maiores dolor quam error molestiae quaerat
          aspernatur blanditiis. Cupiditate expedita eveniet omnis sed libero quod sapiente quis optio dicta beatae
          possimus modi, asperiores esse molestias eius ab quasi cum dolorum, numquam totam. Quae sed obcaecati aliquam
          perspiciatis sapiente doloribus nisi dicta! Sapiente dolores, atque vero consequatur quia eaque deleniti amet
          quidem facilis aperiam ipsum molestias aut harum voluptatibus ab id voluptas! Illum consequatur quae nihil,
          quia ullam excepturi culpa consequuntur magni autem aliquid animi doloremque obcaecati quaerat voluptates modi
          minus et, fugiat eum quasi cumque rem sint ipsa. Voluptatum iure reiciendis odio sunt accusantium minima quo
          facilis voluptas cumque cum! Et tenetur, ipsa animi in, itaque earum quibusdam quae rem hic voluptates porro,
          modi nostrum repellat soluta laudantium accusamus odit eligendi explicabo velit cum suscipit. Dolorum
          architecto omnis dolores provident voluptate, fuga repudiandae ratione sunt quia culpa ea numquam pariatur
          corrupti nemo quis dignissimos, explicabo quos, sapiente iusto eaque enim quisquam accusantium facere
          temporibus? Accusantium, numquam, vero officiis dolorum itaque dicta a dolorem corrupti corporis molestiae
          repellat fugit eius ea qui nostrum voluptate animi similique consequatur. Commodi non odio expedita illum
          deleniti dolorum esse rem debitis ab? Unde, nam dolores nihil minima optio dignissimos mollitia perferendis
          eligendi, harum quos quis nesciunt nisi natus voluptates doloribus voluptatem vel ipsa fugit iure cupiditate
          odio in. Voluptate harum vel architecto facilis sed et, pariatur illo eveniet dolores quaerat molestias quo
          quae exercitationem ipsum obcaecati perspiciatis accusamus ab assumenda. Nesciunt cum fuga eligendi,
          perferendis magnam repellat aperiam beatae quis, blanditiis harum dolorem explicabo, consectetur quod et illum
          pariatur delectus ab distinctio repudiandae amet expedita aspernatur ratione deleniti laboriosam.
          Necessitatibus, laudantium adipisci excepturi quibusdam accusantium quod ullam hic debitis saepe corporis
          enim! Quam eveniet magni est provident atque consequatur praesentium dolorem aspernatur necessitatibus
          nesciunt explicabo optio facilis, rem dolore sed natus, enim minima iusto molestias cum distinctio sunt rerum
          voluptas? Dolor explicabo deleniti in alias, nobis pariatur, repudiandae optio quis numquam ut corporis dicta
          quisquam dolores impedit? Sit, cupiditate obcaecati illo quas iure magni eum deserunt nostrum totam, iusto ad
          provident veritatis! Eos distinctio, provident aliquam nesciunt labore reiciendis voluptates odio esse earum
          repellendus repellat saepe quia maiores, velit nisi corrupti, deserunt laborum est voluptatem fugiat vitae.
          Animi quod eos eaque fugiat. Quidem et commodi animi iure hic in voluptatibus veniam accusantium itaque, at
          expedita, ea repellendus! A aut consequatur accusantium neque nesciunt! Debitis culpa excepturi facere, harum
          deleniti magnam inventore fugiat eius impedit corrupti quis eveniet. Consequuntur in quaerat sed nemo quas
          officia voluptatibus cum eius, blanditiis et. Iure id hic natus voluptatem suscipit. Soluta, nesciunt
          voluptate ipsum sed nemo voluptas. Placeat, eaque voluptate. Magni temporibus et similique dolore aliquid sit
          officiis voluptatibus, soluta quia qui quo maxime fuga ex molestiae consectetur sed voluptates reprehenderit
          autem quos facere. Distinctio perspiciatis suscipit delectus ab. Repudiandae dolor, accusantium inventore
          laudantium necessitatibus dolore, non quos consequuntur recusandae aspernatur accusamus modi beatae quae
          magni. Itaque voluptas blanditiis maiores culpa qui quae, aut in, ut quibusdam optio, similique excepturi
          labore quis! Numquam laudantium corrupti id sunt quibusdam eum sapiente ducimus repellat, inventore unde
          fugiat rem labore amet, expedita dignissimos excepturi maxime dolorem error nostrum minus explicabo quidem?
          Laboriosam hic numquam aliquam nobis id sunt, deserunt natus animi commodi, nulla aliquid cum ut magnam
          excepturi. Ex ratione dolores impedit eius aperiam enim, nesciunt molestias illum vero, itaque optio fugiat
          voluptatem vitae ut blanditiis error. Magni vel recusandae natus, facilis debitis corrupti nam similique dolor
          soluta ex nihil beatae ab assumenda odio a atque, laboriosam commodi quia, vero amet consequatur quas. Hic
          quasi, blanditiis laudantium tempore tenetur officiis ipsum corrupti ad velit natus dicta eum accusantium. Eos
          distinctio, nemo, cum ipsa repudiandae voluptatum ea reprehenderit cupiditate molestias veritatis doloribus
          rem sapiente ipsam. Nesciunt aut, non recusandae ullam quod obcaecati illo, eum at optio laboriosam eaque
          impedit iure adipisci quia cumque esse repellendus fuga repellat voluptates tempore culpa voluptatibus quos
          excepturi. Ipsa voluptatem officiis facilis dicta minima quaerat delectus reiciendis, quisquam ad quas
          voluptate provident explicabo temporibus cupiditate dolore libero vitae commodi exercitationem ducimus,
          veritatis, quos ipsum? Id omnis aliquid commodi debitis ratione assumenda cupiditate, modi sed expedita,
          provident eligendi veniam molestias. Blanditiis earum explicabo neque nam enim facere excepturi, cupiditate
          quo fugiat non, provident laborum nemo dolorum animi perspiciatis exercitationem quis. Obcaecati officia fuga
          non modi minima nihil dolorem, cupiditate rerum quod a? Amet, dolores facilis! Ea labore sint corporis maxime
          repellat aliquam odit placeat eaque adipisci asperiores facere soluta nam error, earum maiores ducimus.
          Voluptatum ex, deleniti veniam quis quasi aspernatur earum repellendus harum ullam itaque repudiandae qui
          quidem corporis molestias expedita porro eveniet assumenda sapiente velit commodi iure dignissimos alias.
          Obcaecati commodi enim maxime cum adipisci. Saepe labore accusantium itaque possimus id aliquam sit, ad
          molestias velit voluptate maiores eaque nostrum ipsam? Facilis minus quod fugiat non, consectetur consequatur
          enim error at autem, fugit quas cupiditate sint. Dicta expedita fuga, nostrum voluptas sed beatae provident
          facere quae dolorum totam tenetur nam neque, inventore delectus amet laboriosam officia ratione doloribus
          eligendi labore voluptate dolor. Rem, rerum fuga aspernatur voluptate sequi doloribus? Minima deleniti ipsam
          facere architecto quaerat dolores debitis numquam quis corporis commodi vitae reiciendis, expedita possimus
          enim ipsum consequatur magni? Distinctio est ut id tempore asperiores facere nesciunt aperiam magni debitis
          explicabo saepe aliquam laudantium, esse, nostrum ipsam quia iusto iure perferendis quos reiciendis enim
          magnam. Corporis omnis at, natus commodi eaque aperiam placeat error voluptatum aliquam. Laudantium itaque
          eaque perspiciatis voluptatem alias aliquam aut nemo, magnam animi atque, ullam iste vitae numquam labore
          officiis illo at error, sed rerum! Mollitia cum eligendi nisi blanditiis repellat velit ipsa rerum deleniti
          nostrum excepturi nobis, reprehenderit maiores at labore asperiores hic ducimus corporis et eos laborum sequi
          enim optio. Saepe facere totam consequatur unde fugit quis praesentium tempora.`,
            drawer01: {
                title: 'drawer01',
                isDrawerOpen: false
            },
            drawer02: {
                title: 'drawer02',
                isDrawerOpen: false
            },
            drawer03: {
                title: 'drawer03',
                isDrawerOpen: false
            },
            dialog01: {
                title: 'dialog01',
                isDialogOpen: false
            },
            dialog02: {
                title: 'dialog02',
                isDialogOpen: false
            },
            dialog03: {
                title: 'dialog03',
                isDialogOpen: false
            },
        };
    },
});
