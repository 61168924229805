import { TripValidationStatusType, TripValidationsNames, TripValidationErrMsg, Products } from '@/types/consts';
import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import { loggerService } from '@/services/logger.service';
import { paymentService } from '@/services/payment.service';
import { SuppliersId } from '@/modules/products/hotels/models/consts';
import { useVuelidate } from '@vuelidate/core';
export default defineComponent({
    components: {},
    name: 'FormOfPayment',
    data() {
        return {
            // hotelQuoteDetails: null as IHotelQuoteDetails | null,
            dialogVisible: false,
            userInfo: {},
            statusType: TripValidationStatusType,
            errMsg: TripValidationErrMsg,
            isCvvRequired: false,
            markFormOfPayment: false,
            cvvNumber: '',
            markFields: false,
            noOwner: false,
            isCreditCard: false,
            isInvoice: false,
            isTmc: false,
            isLodge: false,
            isPersonal: false,
            isTraveler: false,
            isLoading: false,
            cardName: '',
            creditCardType: '',
            dateUpdated: '',
            tripValidations: [],
            availableCreditCards: [],
            selectedCreditCard: {},
        };
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        isConfirmed: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    async created() {
        await this.loadData(true);
        eventBus.$on('reloadFormOfPayment', this.reloadFormOfPayment);
        eventBus.$on('markFormOfPaymentNotValid', this.markFormOfPaymentNotValid);
        eventBus.$on('checkoutActivated', this.checkoutActivated);
    },
    methods: {
        async loadData(onCreate = false) {
            this.isLoading = true;
            try {
                this.tripValidations = await this.$store.getters['tripStore/tripValidations'];
                await this.setFormOfPayment();
                if (this.isBillBack) {
                    this.loadSelectedCreditCard();
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.reloadFormOfPayment(onCreate);
                this.isLoading = false;
            }
        },
        async loadSelectedCreditCard() {
            this.selectedCreditCard = (await paymentService.selectedCreditCard(this.quote.quoteId)) || null;
        },
        async loadAvailableCreditCards() {
            const res = (await paymentService.availableCreditCards(this.quote.quoteId, this.trip.id));
            this.availableCreditCards = res.corporateCreditCards;
        },
        markFormOfPaymentNotValid() {
            // called from eventBus tripCart
            this.markFormOfPayment = true;
        },
        async reloadFormOfPayment(onCreate) {
            this.tripValidations = await this.$store.getters['tripStore/tripValidations'];
            if (onCreate) { //On Create, load data only if tripValidations is empty. it's prevent infinity loop
                if (this.tripValidations) {
                    await this.$store.dispatch({
                        type: 'tripStore/loadTripValidations',
                        tripId: this.trip.id,
                    });
                }
            }
            else {
                await this.$store.dispatch({
                    type: 'tripStore/loadTripValidations',
                    tripId: this.trip.id,
                });
            }
            this.tripValidations = await this.$store.getters['tripStore/tripValidations'];
            this.setFormOfPayment();
            if (this.isBillBack) {
                this.loadSelectedCreditCard();
            }
            eventBus.$emit('validateRequiredInfo', false, true); // trip-cart.ts
        },
        async setFormOfPayment() {
            if (!this.validations) {
                return;
            }
            this.cardName = this.validations.creditCardName || '';
            this.creditCardType = this.validations.creditCardType || 'credit-card'; // fallback icon
            this.isCvvRequired = this.validations.isCvvRequired ? this.validations.isCvvRequired : false;
            this.isCreditCard = this.validationsErrorMsg === 'cc';
            this.isInvoice = this.validationsErrorMsg?.toLowerCase() === 'invoice';
            // ownerType
            this.noOwner = this.ownerType === null;
            this.isTraveler = this.ownerType === 'traveler';
            this.isLodge = this.ownerType === 'lodge'; // corporate
            this.isTmc = this.ownerType === 'tmc';
            this.isPersonal = this.ownerType === 'personal';
        },
        hasValidation(validationsNames) {
            return this.quoteValidations?.Validations?.some((v) => v.name === validationsNames) || false;
        },
        showAddPassengersMsg() {
            eventBus.$emit('markEmptyTravelers');
            window.scrollTo(0, 0);
        },
        showSelectCreditCard() {
            eventBus.$emit('showSelectCreditCard', this.quote); // open drawer
        },
        validateCvv() {
            if (this.cvvNumber.length > 4) {
                const maxDigs = 4;
                this.cvvNumber = this.cvvNumber.substring(0, maxDigs);
                return;
            }
            // only numbers
            const cvv = this.cvvNumber.replace(/[^0-9]/, '');
            if (!cvv) {
                this.cvvNumber = '';
            }
            this.cvvNumber = this.cvvNumber.toString();
            const cvvNumber = { quoteId: this.quote.quoteId, cvvNumber: this.cvvNumber };
            // we sent this emit before we have 3 digs to allow 'addCvvNumbers' to do validateRequiredInfo() for each dig
            eventBus.$emit('addCvvNumbers', cvvNumber);
            this.markFields = this.isCvvValid;
        },
        checkoutActivated() {
            this.markFields = true;
        },
    },
    computed: {
        validations() {
            return this.quoteValidations?.Validations?.find((v) => v.name.includes(TripValidationsNames.FormOfPayment));
        },
        noFormOfPayment() {
            return this.validations?.status !== this.statusType.OK;
        },
        validationsErrorMsg() {
            return this.validations?.errMsg.toLowerCase() || null;
        },
        isCvvValid() {
            return this.cvvNumber.length < 3 ? true : false;
        },
        ownerType() {
            return this.validations?.ownerType?.toLocaleLowerCase() || null;
        },
        isBillBack() {
            return this.validations?.parameteValue
                ? this.validations?.parameteValue[0] === 'virtualCard' && this.validations.validationType === 1
                : false;
        },
        isVirtualCard() {
            return this.validations?.parameteValue ? this.validations?.parameteValue[0] === 'virtualCard' : false;
        },
        quoteValidations() {
            const validations = this.$store.getters['tripStore/tripValidations'];
            const quoteValidations = validations.find((r) => r.QuoteId === this.quote.quoteId);
            return quoteValidations;
        },
        last4CardDigits() {
            const validations = this.quoteValidations || null;
            const card = validations?.Validations.find((v) => (v.errMsg === 'CC' || v.name === 'Form of Payment') && v.creditCardLastDigits);
            if (!card) {
                return '';
            }
            return card?.creditCardLastDigits;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isBillbackInfoComplete() {
            if (!this.selectedCreditCard) {
                return false;
            }
            return (!!this.selectedCreditCard.paymentRestriction && (!!this.selectedCreditCard.paymentType || this.isVirtualCard));
        },
        getTripPassengers() {
            const trip = this.$store.getters['tripStore/trip'];
            return trip.passengers;
        },
        hasPassengers() {
            return this.getTripPassengers.length > 0;
        },
        changeDate() {
            return new Date().getTime();
        },
        isManual() {
            return this.quote.isManual;
        },
        isExpedia() {
            if (!this.quote || !this.quote.sourceSystem) {
                return false;
            }
            return this.quote.sourceSystem === hotelsConsts.SuppliersId.EXPEDIARAPID;
        },
        isRatehawk() {
            if (!this.quote) {
                return false;
            }
            return this.quote.sourceSystem === hotelsConsts.SuppliersId.RATEHAWK;
        },
        canEditPayment() {
            if (!this.quote ||
                this.isRatehawk ||
                (this.quote.product === Products.Hotel && this.isInvoice) ||
                (this.quote && this.quote.sourceSystem === SuppliersId.TRANSFERZ && this.isInvoice)) {
                return false;
            }
            return (this.loggedinUser.permissions.some((p) => p.includes('СanSelectFopAtTheCart')) ||
                !this.availableCreditCards); // when no corporate cards allow the user to edit FOP
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        hasSpecialRequestValidation() {
            return this.hasValidation(TripValidationsNames.SpecialRequest);
        },
        showCardIcon() {
            return this.validations?.status === this.statusType.OK && !this.isInvoice && !this.isVirtualCard;
        },
    },
    unmounted() {
        eventBus.$off('reloadFormOfPayment', this.reloadFormOfPayment);
        eventBus.$off('markFormOfPaymentNotValid', this.markFormOfPaymentNotValid);
        eventBus.$off('checkoutActivated', this.checkoutActivated);
    },
});
