export const pendoAnalytics = { initPendo };

function initPendo(user) {
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function in your authentication promise handler or callback when your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.

  // eslint-disable-next-line no-undef
  pendo.initialize({
    visitor: {
      id: user?.id, // Required if user is logged in, default creates anonymous ID
      email: user?.email, // Recommended if using Pendo Feedback, or NPS Email
      corporationId: user?.corporationId,
      agencyId: user?.agencyId,
      isCorporateUser: user?.isCorporateUser,
      isBooker: user?.permissions.includes('IsBooker'),
      full_name: user?.personalDetails.firstName + ' ' + user?.personalDetails.lastName, // Recommended if using Pendo Feedback
      env: process.env.NODE_ENV,
      newUI: true,
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    // account: {
    //   id: 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
    //   // name:         // Optional
    //   // is_paying:    // Recommended if using Pendo Feedback
    //   // monthly_value:// Recommended if using Pendo Feedback
    //   // planLevel:    // Optional
    //   // planPrice:    // Optional
    //   // creationDate: // Optional

    //   // You can add any additional account level key-values here,
    //   // as long as it's not one of the above reserved names.
    // },
  });
}
