<template>
  <section class="app-carousel">
    <Flicking
      :options="options"
      ref="flicking"
      @changed="$emit('changed', $event)"
      @select="$emit('selected', $event)"
      @move-start="$emit('moveStart', $event)"
      @move-end="$emit('moveEnd', $event)"
      @will-restore="$emit('willRestore', $event)"
      @reach-edge="$emit('reachEdge', $event)">
      <slot />
    </Flicking>
  </section>
</template>
<script>
import { defineComponent } from 'vue';
import Flicking from '@egjs/vue3-flicking';

export default defineComponent({
  name: 'AppCarousel',
  props: {
    options: {
      type: Object,
      default() {
        return {
          bound: true,
          align: 'prev',
        };
      },
    },
  },
  components: {
    Flicking: Flicking,
  },
  methods: {
    moveTo(idx) {
      this.$refs.flicking.moveTo(idx);
    },
    next() {
      this.$refs.flicking.next();
    },
    prev() {
      this.$refs.flicking.prev();
    },
    getIdx() {
      return this.$refs.flicking.index;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
@import '@egjs/vue3-flicking/dist/flicking.css';

.app-carousel {
  max-width: 100%;
}
</style>
