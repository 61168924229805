import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-567b3ec4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "oop-justification is-flex is-flex-direction-column" };
const _hoisted_2 = { class: "oop-violations mt-2" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_input = _resolveComponent("el-input");
    const _component_app_select = _resolveComponent("app-select");
    const _component_app_btn = _resolveComponent("app-btn");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.justification.TravelPolicyViolations, (violation, idx) => {
                return (_openBlock(), _createElementBlock("p", {
                    key: violation,
                    class: "out-of-policy-label is-flex is-flex-diection-column"
                }, [
                    (_ctx.justification.TravelPolicyViolations.length > 1)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode("(" + _toDisplayString(idx + 1) + ")", 1 /* TEXT */)
                        ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true),
                    _createTextVNode(" " + _toDisplayString(violation.split(';;')[0]), 1 /* TEXT */)
                ]));
            }), 128 /* KEYED_FRAGMENT */))
        ]),
        (_ctx.justification.JustificationType === _ctx.JustificationType.Text)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                type: "textarea",
                placeholder: _ctx.$t('trip.oopJustification.addJustification'),
                class: _normalizeClass(["py-2", { 'is-danger': _ctx.isShowRedBorder && !_ctx.freeTextJustification }]),
                modelValue: _ctx.freeTextJustification,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.freeTextJustification) = $event)),
                onInput: _ctx.onEnterJustification
            }, null, 8 /* PROPS */, ["placeholder", "class", "modelValue", "onInput"]))
            : _createCommentVNode("v-if", true),
        (_ctx.justification.JustificationType === _ctx.JustificationType.Select)
            ? (_openBlock(), _createBlock(_component_app_select, {
                key: 1,
                defaultStyle: "",
                options: _ctx.selectOptions,
                class: _normalizeClass({ 'is-danger': _ctx.isShowRedBorder && !_ctx.selectedJustificationOption }),
                placeholder: _ctx.$t('trip.oopJustification.selectJustification'),
                modelValue: _ctx.selectedJustificationOption,
                "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.selectedJustificationOption) = $event)),
                    _ctx.onEnterJustification
                ]
            }, null, 8 /* PROPS */, ["options", "class", "placeholder", "modelValue", "onUpdate:modelValue"]))
            : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
            class: _normalizeClass(["is-flex is-justify-content-flex-end save-container", { hidden: _ctx.isHidingSaveBtn }])
        }, [
            _createVNode(_component_app_btn, {
                type: "text",
                onClick: _ctx.onSaveJustification
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('btn.save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
        ], 2 /* CLASS */)
    ]));
}
