import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, maxLength, required, requiredIf } from '@vuelidate/validators';
import validationsBasic from './validations-basic.vue';
import validationsEach from './validations-each.vue';
import validationsEachTraveler from './validations-each-traveler.vue';
export default defineComponent({
    name: 'validationsIndex',
    components: {
        validationsBasic,
        validationsEach,
        validationsEachTraveler
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            markFields: false,
            isAddressRequired: false,
            formData: {
                name: '',
                email: '',
                address: '',
            }
        };
    },
    validations() {
        return {
            hotelSearch: {
                destination: { required },
            },
            formData: {
                name: { required, maxLength: maxLength(10), isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value), },
                email: { required, email },
                address: { required: requiredIf(() => { return this.isAddressRequired; }) }, // required but on condition
            },
        };
    },
});
