<template>
  <section class="airport-autocomplete">
    <div class="auto-container">
      <app-autocomplete
        v-model="inputValue"
        :searchFunc="airportQuerySearch"
        :name="name"
        :placeholder="placeholder || $t('autocomplete.enterAirportLocation')"
        :mobileDialogTitle="$t('autocomplete.enterAirportLocation')"
        @change="handleChange"
        @selected="handleSelect"
        @keydown.enter="handleEnter"
        @autoFillField="autoFillField"
        @focused="$emit('focused')"
        :showIcon="showIcon"
        popperClass="boxed-autocomplete-popper"
        :defaultStyle="defaultStyle">
        <!-- Props Exp -->
        <!-- inputValue - pass to autocomplete the string it needs to show instead of the placeholder (not the full results object, just the name in this case) -->
        <!-- searchFunc - pass to autocomplete the search function to get the relevant results, all the logic should be in this component -->
        <!-- Props Exp -->

        <!-- icon inside the autocomplete input -->
        <template #prefix-icon>
          <span class="material-symbols-outlined">{{ iconName }}</span>
        </template>
        <!-- END icon inside the autocomplete input -->

        <!-- layout for each autocomplete result -->
        <template #autocomplete-result="{ result }">
          <div class="autocomplete-result">
            <div class="autocomplete-result-icon">
              <span id="flight" class="material-symbols-outlined">flight</span>
            </div>
            <div class="autocomplete-result-text">
              <p class="has-text-weight-bold">
                <!-- {{ result.Name.split(',')[0] }} -->
                {{ result.Name }}
              </p>
              <p class="ellipsis">{{ result.Name.split(',').slice(1).join(', ') }}</p>
            </div>
          </div>
        </template>
        <!-- END layout for each autocomplete result -->
      </app-autocomplete>
    </div>
  </section>
</template>

<script>
import * as _ from 'lodash';
import { airportService } from '@/services/airport.service';
import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';

import appAutocomplete from '@/components/form/app-autocomplete.vue';

export default defineComponent({
  name: 'AirportAutocomplete',
  components: {
    appAutocomplete,
  },
  props: {
    iconName: {
      type: String,
      default: 'location_on',
    },
    modelValue: {
      type: [Object, String],
      default: null,
    },
    placeholder: {
      type: String,
    },
    isAutoPopulate: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    name: {
      type: Boolean,
      required: false,
    },
    defaultStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minCharsForSearch: 3,
      inputValue: '',
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.updateInputValue(this.modelValue);
      },
      immediate: true,
    },
  },
  async created() {
    if (
      this.isAutoPopulate &&
      this.homeAirport &&
      this.modelValue &&
      typeof this.modelValue === 'object' &&
      !Object.keys(this.modelValue)?.length
    ) {
      const res = await this.airportQuerySearch(this.homeAirport);
      this.handleSelect(res[0]);
    }
  },
  methods: {
    updateInputValue(modelValue) {
      let newValue = '';
      if (typeof modelValue === 'string') {
        newValue = modelValue;
      } else {
        newValue = modelValue ? modelValue.Name : '';
      }
      //A special case (see bug 52135) when we select the same value as before but need to trigger a watch
      //inside app-autocomplete to change the 'query' .
      //so we change it to '' and update on nextTick
      this.inputValue = ''; //Clear the current value
      this.$nextTick(() => {
        this.inputValue = newValue; // update new value in next render
      });
    },
    async autoFillField(val) {
      const res = await this.airportQuerySearch(val);

      if (res?.length) {
        this.handleSelect(res[0]);
        this.updateInputValue(res[0]);
      }
    },
    handleChange(val) {
      //Gets a string of the value of the autocomplete and passes it to the inputs component
      this.$emit('change', val);
    },
    handleEnter(event) {
      // 49700 - advanced search opens when you select autocomplete by pressing enter.
      event.preventDefault();
    },

    handleSelect(item) {
      //Gets the full item result and passes it to the inputs component
      this.$emit('item-selected', item);
      this.$emit('change', item.Name);
      this.$emit('update:modelValue', item);
    },
    async airportQuerySearch(queryString) {
      // make sure to return array

      let results = [];
      if (!!queryString && queryString.length >= this.minCharsForSearch) {
        results = await this.searchQueryStringInAllAirports(queryString.trim());
      }
      return results;
    },
    async searchQueryStringInAllAirports(queryString) {
      let results = [];
      const allAirports = utilService.deepClone(this.allAirports);

      if (Object.keys(allAirports).length) {
        // the following logic is trying to replicate the existing logic in BE, that we didn't need before on FE because BE was responsible for it

        // allAirports is an object, not an array, therefore we use "for in"
        for (const key in allAirports) {
          // first step- push all relevant results into the results array.
          // if the "Name" of the result contains the "querystring"- we add it.
          if (allAirports?.[key]?.Name?.toLowerCase().includes(queryString.toLowerCase())) {
            results.push(allAirports[key]);
          }
        }

        // second step- get the index of the first appearance of the "querystring" in the results' "Name" and add it to the result obj
        results.forEach((result) => {
          result.queryStringIndexInResult = result.Name.toLowerCase().indexOf(queryString.toLowerCase());
        });

        // sort by that index(queryStringIndexInResult) to get the most relevant results at the top of the autocomplete suggestion list
        results = _.sortBy(results, 'queryStringIndexInResult');

        // find THE MOST relevant results, regardless of the index of appearance, and get it to the top of the list
        // the most relevant is for example if we search for "las", the most relevant result is the one that its' airport Code === "LAS"
        const mostRelevantAirportIndex = results.findIndex((result) => result.Code === queryString.toUpperCase());
        if (mostRelevantAirportIndex > -1) {
          [results[0], results[mostRelevantAirportIndex]] = [results[mostRelevantAirportIndex], results[0]];
        }
      } else {
        // if for some reason we don't have the airport list in the store or local storage-
        // we'll search for an airport the old way
        results = (await airportService.autocompleteSearch(queryString)) || [];
      }

      return results;
    },
  },
  computed: {
    loggedinUser() {
      return this.$store.getters['authStore/loggedinUser'];
    },
    allAirports() {
      return this.$store.getters['flightStore/allAirports'];
    },
    homeAirport() {
      return this.loggedinUser.preferences.homeAirport;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.airport-autocomplete {
  width: 100%;

  .material-symbols-outlined {
    color: $gray-2300;
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 20;
  }
}

.autocomplete-result {
  .material-symbols-outlined {
    color: $gray-2300;
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 20;
    opacity: 0.7;
  }
}
</style>
