<template>
  <date-picker
    :firstDayOfWeek="firstDayOfWeek"
    :locale="userLang"
    :min-date="minDate"
    :max-date="maxDate"
    class="daterange-mobile"
    @update:modelValue="emitValue"
    v-model.range="dates"
    mode="date" />
</template>

<script>
import { defineComponent } from 'vue';
import { formatISO } from 'date-fns';
import { DatePicker } from 'v-calendar';
import { WeekDayOptions } from '@/types/consts';

export default defineComponent({
  name: 'DaterangeMobile',
  components: { DatePicker },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {
          start: new Date(),
          end: new Date(),
        };
      },
    },
    startPlaceholder: {
      type: String,
      required: false,
    },
    endPlaceholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dates: this.modelValue,
      currentDate: new Date(),
    };
  },
  methods: {
    emitValue(val) {
      // ignore timezones
      const startDate = formatISO(val.start);
      const endDate = formatISO(val.end);
      const start = this.$filters.date(startDate, 'yyyy-MM-dd');
      const end = this.$filters.date(endDate, 'yyyy-MM-dd');
      this.dates = { start, end };
      this.$emit('change', { start, end });
    },
  },
  computed: {
    minDate() {
      return Date.now();
    },
    maxDate() {
      return new Date(this.currentDate.getFullYear() + 1, this.currentDate.getMonth(), this.currentDate.getDate());
    },
    startDateType() {
      return typeof this.dates.start;
    },
    userLang() {
      return this.$store?.getters['authStore/loggedinUser']?.preferences?.language || 'en-GB';
    },
    firstDayOfWeek() {
      const loggedinUser = this.$store?.getters['authStore/loggedinUser'];
      const firstDayOptions = loggedinUser?.firstDayOptions || 0;
      const day = WeekDayOptions[firstDayOptions] || 0;
      // for the mobile datepicker, we need to add 1
      return day + 1;
    },
  },
  watch: {
    modelValue(newVal) {
      this.dates.start = newVal[0];
      this.dates.end = newVal[1];
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/style/abstracts';
@import '@/assets/style/basics';

.daterange-mobile {
  &.vc-container {
    width: 100%;
    border: none;
  }
  --vc-accent-600: #{$primary};
  .vc-blue {
    --vc-accent-600: #{$primary};
  }
}
</style>
