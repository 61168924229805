import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-090ecbab"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "fare-rules" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = {
    key: 0,
    class: "skeletons padding"
};
const _hoisted_4 = { class: "skel-row1 flex" };
const _hoisted_5 = { class: "skel-image" };
const _hoisted_6 = { class: "skel-text" };
const _hoisted_7 = { class: "skel-row2" };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = { class: "mb-4" };
const _hoisted_10 = {
    key: 3,
    class: "mb-4"
};
const _hoisted_11 = { class: "has-text-weight-bold" };
const _hoisted_12 = { class: "note" };
const _hoisted_13 = { class: "fare-rules-content-wrapper" };
const _hoisted_14 = {
    key: 0,
    class: "main-headline"
};
const _hoisted_15 = {
    key: 1,
    class: "main-content"
};
const _hoisted_16 = ["innerHTML"];
const _hoisted_17 = ["innerHTML"];
const _hoisted_18 = ["target", "href"];
const _hoisted_19 = { key: 2 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_skeleton_item = _resolveComponent("el-skeleton-item");
    const _component_el_skeleton = _resolveComponent("el-skeleton");
    const _component_el_tab_pane = _resolveComponent("el-tab-pane");
    const _component_el_tabs = _resolveComponent("el-tabs");
    const _component_el_option = _resolveComponent("el-option");
    const _component_el_select = _resolveComponent("el-select");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_el_skeleton_item, { variant: "image" })
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", null, [
                                    _createVNode(_component_el_skeleton, {
                                        rows: 4,
                                        animated: ""
                                    })
                                ])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_el_skeleton, {
                                rows: 10,
                                animated: ""
                            })
                        ])
                    ]))
                    : _createCommentVNode("v-if", true)
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                    (_ctx.segmentToShowIndex === null)
                        ? (_openBlock(), _createBlock(_component_el_tabs, {
                            key: 0,
                            modelValue: _ctx.currentTabIndex,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.currentTabIndex) = $event)),
                            class: "fare-rules-tabs"
                        }, {
                            default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fareRules.fareBasisRuleLines, (fareRuleLine, idx) => {
                                    return (_openBlock(), _createBlock(_component_el_tab_pane, {
                                        label: `${fareRuleLine.origin} - ${fareRuleLine.destination}`,
                                        name: idx.toString(),
                                        key: `tab-${idx}`
                                    }, null, 8 /* PROPS */, ["label", "name"]));
                                }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]))
                        : (_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex]?.fareBasis)
                            ? (_openBlock(), _createBlock(_component_el_tabs, {
                                key: 1,
                                modelValue: _ctx.currentTabIndex,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.currentTabIndex) = $event)),
                                class: "fare-rules-tabs"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_el_tab_pane, {
                                        label: `${_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex].origin} - ${_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex].destination}`,
                                        name: _ctx.currentTabIndex
                                    }, null, 8 /* PROPS */, ["label", "name"])
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"]))
                            : _createCommentVNode("v-if", true),
                    (_ctx.fareRulesSectionOptions?.length)
                        ? (_openBlock(), _createBlock(_component_el_select, {
                            key: 2,
                            ref: "fareRulesSelect",
                            modelValue: _ctx.selectedFareRulesSection,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.selectedFareRulesSection) = $event)),
                            class: "fare-rules-section-select mt-2 mb-4",
                            onChange: _ctx.jumpToSection,
                            onVisibleChange: _ctx.hideiOSKeyboard,
                            placeholder: `${_ctx.$t('flight.jumpTo')}...`
                        }, {
                            default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fareRulesSectionOptions, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        value: _ctx.removeHtmlTags(item),
                                        innerHTML: item
                                    }, null, 8 /* PROPS */, ["value", "innerHTML"]));
                                }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue", "onChange", "onVisibleChange", "placeholder"]))
                        : _createCommentVNode("v-if", true),
                    (_ctx.fareRules?.fareBasisRuleLines[_ctx.currentTabIndex]?.carrier)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("p", null, [
                                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('flight.carrier')) + ":", 1 /* TEXT */),
                                _createTextVNode(" " + _toDisplayString(_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex].carrier), 1 /* TEXT */)
                            ])
                        ]))
                        : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('flight.pleaseNote')) + ":", 1 /* TEXT */),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('flight.fareRulesMsg')), 1 /* TEXT */)
                    ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                    (_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex]?.fareBasis)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.$t('flight.fareBasis')) + " - " + _toDisplayString(_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex]?.fareBasis), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                    (_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex]?.ruleLines?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fareRules.fareBasisRuleLines[_ctx.currentTabIndex].ruleLines, (ruleLine, idx) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: `rule-line-${idx}`
                                }, [
                                    _createElementVNode("p", {
                                        ref_for: true,
                                        ref: ruleLine.title ? _ctx.removeHtmlTags(ruleLine.title) : '',
                                        class: "rule-headline",
                                        innerHTML: ruleLine.title
                                    }, null, 8 /* PROPS */, _hoisted_16),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ruleLine.rule, (rule, idx) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                            (ruleLine.title !== '<u>Rules Link</u>')
                                                ? (_openBlock(), _createElementBlock("p", {
                                                    key: `rule-${idx}`,
                                                    innerHTML: rule
                                                }, null, 8 /* PROPS */, _hoisted_17))
                                                : (_openBlock(), _createElementBlock("a", {
                                                    key: `rule-link-${idx}`,
                                                    class: "rule-link",
                                                    target: _ctx.isMobile ? '_self' : '_blank',
                                                    href: rule
                                                }, _toDisplayString(rule), 9 /* TEXT, PROPS */, _hoisted_18))
                                        ], 64 /* STABLE_FRAGMENT */));
                                    }), 256 /* UNKEYED_FRAGMENT */))
                                ]));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_19, "NO FARE RULES FOUND"))
                ])
            ]))
    ]));
}
